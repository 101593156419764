import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import routers from "../Landing/routers";

interface IProps {
  currentIndex?: number;
  isHome?: boolean;
}

const Navigation = ({ currentIndex }: IProps) => {
  const handleBottom = (index = 1) => {
    if (typeof window !== undefined) {
      window.scrollTo(0, window.innerHeight * index);
    }
  };

  const hide = currentIndex !== 0;

  return (
    <>
      <section className="is-first lg:block fixed bottom-0 right-0 top-0 left-0 z-40">
        <div className="container mx-auto flex justify-end h-full">
          <div className="cn h-full flex flex-col items-center justify-between">
            <div className="hidden lg:block cn_top"></div>
            <div className="hidden lg:flex group flex-col space-y-10 justify-center items-center">
              {routers?.map((_, index) => (
                <div
                  className="w-5 h-5 cursor-pointer flex items-center justify-center"
                  onClick={() => handleBottom(index)}
                >
                  <div
                    className={`h-1 w-1 bg-white rounded cursor-pointer  ${
                      currentIndex === index
                        ? "bg-opacity-100"
                        : "bg-opacity-50"
                    }`}
                  ></div>
                </div>
              ))}
            </div>
            <div className="flex flex-col space-y-5 relative lg:justify-center lg:items-center h-full lg:h-auto pb-10 lg:pb-0 justify-end items-end">
              <div
                className={`hidden lg:block cn_bottom_start transition-all`}
                style={
                  hide
                    ? {
                        height: "30vh",
                      }
                    : {}
                }
              ></div>
              <button
                className={`group hidden lg:inline-block c-home__scroll-btn transition-all c-btn-scroll relative  ${
                  hide
                    ? "opacity-0 invisible pointer-events-none"
                    : "opacity-100"
                }`}
                style={
                  hide
                    ? {
                        opacity: 0,
                        visibility: "hidden",
                      }
                    : {}
                }
                onClick={() => handleBottom(1)}
              >
                <div className="c-btn-scroll__big-bg absolute bg-white rounded-full" />{" "}
                <div className="absolute top-0 left-0 h-full w-full rounded-full overflow-hidden">
                  <div className="c-btn-scroll__bg absolute top-0 left-0 h-full w-full bg-white rounded-full" />{" "}
                  <div className="c-btn-scroll__bg absolute top-0 left-0 h-full w-full bg-main rounded-full" />
                </div>
                <div className="c-btn-scroll__icon flex items-center justify-center t-icon t-icon--arrow-bottom absolute u-pos-center">
                  <StaticImage
                    height={7}
                    width={9}
                    src="../../images/arrow-bottom-white.svg"
                    alt="Icon arrow white"
                  />
                </div>
                <div className="opacity-0 flex items-center justify-center group-hover:opacity-100 c-btn-scroll__icon t-icon t-icon--arrow-bottom absolute u-pos-center">
                  <StaticImage
                    height={7}
                    width={9}
                    src="../../images/arrow-bottom-blue.svg"
                    alt="Icon arrow blue"
                  />
                </div>
              </button>
              <div
                className="hidden lg:block cn_bottom_end"
                style={
                  hide
                    ? {
                        opacity: 0,
                        display: "none",
                      }
                    : {}
                }
              ></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navigation;
