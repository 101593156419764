import React, { useState, useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import Header from "../Header";
import Effect from "./Effect";
import { useEffect } from "react";
// import homeVideo from "../../assets/home.mp4";

const Screen1 = ({ active }) => {
  const { formatMessage: fm } = useIntl();
  const [hide, setHide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHide(true);
    }, 2500);
  }, []);
  return (
    <>
      <div
        className={`fixed transition-all top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-dark
           ${hide ? "opacity-0 invisible" : "opacity-100"}`}
        style={{
          zIndex: 999,
        }}
      >
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration={2000}
          data-sal-easing="easeInOutQuad"
        >
          <StaticImage
            src="../../images/logo-white.png"
            alt="Steppe Link Holding"
            height={55}
            placeholder="tracedSVG"
          />
        </div>
      </div>

      <Header active={active} key={hide ? "show-header" : "hidden-header"} />

      <div
        className={`h-full lg:overflow-hidden w-full flex relative items-center  z-30`}
      >
        <Effect active={active} />

        <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center transform scale-100">
          {/* <StaticImage
            src="../../images/screen-1.jpg"
            alt=""
            className="w-full h-full object-cover"
          /> */}

          {/* <video autoPlay muted loop className="w-full h-full object-cover ">
            <source src={homeVideo} type="video/mp4" />
          </video> */}
          <StaticImage
            src="../../images/cover_back.jpg"
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="max-w-full lg:max-w-5xl mx-auto flex flex-col lg:flex-row items-center">
          <StaticImage
            src="../../images/toono.png"
            alt=""
            className="w-1/2"
            data-aos="fade-down"
            data-aos-duration="400"
            data-aos-delay="2500"
            data-aos-easing="ease-in-sine"
          />
          <div
            className="z-30 flex flex-col justify-center items-center w-1/2 pl-2"
            data-aos="fade-down"
            data-aos-duration="800"
            data-aos-delay="2500"
            data-aos-easing="ease-in-sine"
          >
            <h1 className="font-black text-2xl lg:text-4xl tracking-wider text-center">
              СТЕППЕ ЛИНК ХОЛДИНГ
            </h1>
            <h2 className="text-center font-medium text-md lg:text-xl uppercase tracking-wider mt-4">
              Үндэсний хөрөнгө оруулагч <br />
              компани
            </h2>
          </div>
        </div>

        {/* <div className="absolute top-0 left-0 right-0 bottom-0 bg-main bg-opacity-20"></div> */}
        {/* <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40"></div> */}
        {/* <div className="container px-4 mx-auto lg:mb-12 tracking-tight relative">
          <h1
            data-sal="slide-up"
            data-sal-duration={1000}
            data-sal-delay="300"
            data-sal-easing="ease-out-back"
            className="text-white w-3/4 lg:w-full font-cool text-2xl lg:text-4xl lg:mb-16 lg:leading-10"
            dangerouslySetInnerHTML={{
              __html: fm({ id: "home-s1-title" }),
            }}
          ></h1>
          <div
            className="hidden lg:flex items-center"
            data-sal="slide-up"
            data-sal-delay="50"
            data-sal-duration="1000"
            data-sal-repeat={active}
          >
            <span
              className="bg-main opacity-50 h-px mr-10"
              style={{
                width: "45%",
              }}
            ></span>

            <span
              data-sal-duration={1000}
              className="whitespace-nowrap text-xs text-white uppercase text-opacity-70"
              data-sal="slide-right"
              data-sal-delay="800"
              data-sal-easing="linear"
            >
              {fm({ id: "scrolling" })}
            </span>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default Screen1;
