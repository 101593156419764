import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";

interface IProps {
  active: boolean;
}

const Effect = ({ active }: IProps) => {
  return (
    <div className="hidden">
      <div
        className={
          "effect-t absolute h-20 z-40 bg-black top-0 left-0 right-0 w-full"
        }
      ></div>
      <div
        className={`effect-b absolute h-20 z-40 bg-black bottom-0 left-0 right-0 w-full`}
      ></div>

      <div
        className={`effect-l absolute h-full w-40 z-40 bg-black bottom-0 left-0 top-0`}
      ></div>

      <div
        className={`effect-r absolute h-full w-56 z-40 bg-black bottom-0 right-0 top-0`}
      ></div>
    </div>
  );
};

export default Effect;
