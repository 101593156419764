import React, { useState, useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import Footer from "../Footer";
import Effect from "./Effect";
import SubHeader from "../Header/SubHeader";
const Screen5 = ({ active }) => {
  const { formatMessage: fm } = useIntl();
  return (
    <>
      <SubHeader
        active={active}
        key={`${active ? "sub-4" : "sub-4-disabled"}`}
      />
      <div
        className={`min-h-full lg:overflow-hidden relative flex w-full bg-white`}
      >
        <Effect active={active} />
        <div className="w-1/4  hidden lg:block absolute overflow-hidden right-0 bottom-0 top-0 bg-black h-full">
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <StaticImage
              src="../../images/section_4.jpg"
              alt=""
              className="h-screen"
            />
          </div>
        </div>
        {/* <div className="flex flex-col  justify-between mb-10"> */}
        <div className="w-full h-full relative">
          <div className="container px-16 mx-auto h-full">
            <div className="flex flex-col lg:flex-row mt-48 lg:mt-28 ">
              <span
                className="hidden lg:block text-sm font-bold lowercase"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="linear"
                data-aos-delay="300"
              >
                04
              </span>
              <div
                className="w-full lg:w-2/12 flex space-x-10 mb-10 lg:mb-0"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="linear"
                data-aos-delay="300"
              >
                <span className="block lg:hidden text-sm font-bold lowercase">
                  04
                </span>
                <span className="text-sm font-bold lowercase block">
                  {fm({ id: "home-hr" })}
                </span>
              </div>
            </div>
            <div
              className="w-full mb-10 md:ml-14 lg:w-1/2  lg:mt-10"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="linear"
              data-aos-delay="300"
            >
              <h1 className="font-cool text-lg lg:text-3xl mb-20">
                {fm({ id: "home-hr-title" })}
              </h1>

              <h3 className="font-cool lg:block text-main italic text-xl lg:text-2xl mb-10 border-b border-white border-opacity-50 pb-5">
                {fm({ id: "home-hr-sub-title" })}
              </h3>
              <p
                className="text-black  lg:block text-base lg:text-base pl-10"
                dangerouslySetInnerHTML={{
                  __html: fm({ id: "home-hr-desc" }),
                }}
              ></p>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      <Footer />
    </>
  );
};
export default Screen5;
