import React, { useState, useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import Effect from "./Effect";
import SubHeader from "../Header/SubHeader";
const Screen2 = ({ active }) => {
  const { formatMessage: fm } = useIntl();
  return (
    <>
      <SubHeader
        active={active}
        key={`${active ? "sub-2" : "sub-2-disabled"}`}
      />
      <div
        className={`h-full lg:overflow-hidden relative flex w-full bg-white`}
      >
        <Effect active={active} />

        <div className="w-3/12 hidden lg:block absolute overflow-hidden right-0 bottom-0 top-0 bg-black h-full">
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <StaticImage src="../../images/section_2.jpg" alt="" />
          </div>
        </div>
        <div className="w-full bg-white h-full">
          <div className="container px-16 mx-auto pt-20">
            <div className="flex flex-col lg:flex-row mt-20 lg:space-x-16">
              <div className="text-sm mb-16 lg:mb-0 font-bold flex items-center lg:items-start space-x-10 lowercase">
                <span
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="linear"
                  data-aos-delay="300"
                >
                  02
                </span>
                <span
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                  data-aos-easing="linear"
                  className="block lg:hidden text-sm font-bold lowercase"
                >
                  {fm({ id: "home-about-sub-title" })}
                </span>
              </div>
              <div className="w-full lg:w-1/2">
                <p
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="700"
                  data-aos-easing="linear"
                  className="font-cool text-lg lg:text-3xl mb-20 text-opacity-60"
                  dangerouslySetInnerHTML={{
                    __html: fm({ id: "home-about-desc" }),
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Screen2;
