import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import * as React from "react";

import { useEffect } from "react";
import { useState } from "react";
import sal from "sal.js";

interface IProps {
  active?: boolean;
  inverse?: boolean;
}

const SubHeader = ({ active, inverse }: IProps) => {
  useEffect(() => {
    sal().update();
  }, []);
  return (
    <div className={`absolute w-full top-0 left-0 right-0 z-20`}>
      <section
        className={`container relative px-4 mx-auto flex items-center my-10 `}
      >
        <div data-sal="fade" data-sal-delay="500" data-sal-duration={2000}>
          {inverse ? (
            <StaticImage
              src="../../images/icon-white.png"
              alt="Steppe Link Holding"
              height={35}
              placeholder="tracedSVG"
            />
          ) : (
            <StaticImage
              src="../../images/icon.png"
              alt="Steppe Link Holding"
              height={35}
              placeholder="tracedSVG"
            />
          )}
        </div>
      </section>
    </div>
  );
};

export default SubHeader;
