import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import Screen3 from "./Screen3";
import Screen4 from "./Screen4";
export default [
  {
    title: "Scrreen1",
    inverse: false,
    component: Screen1,
    first: true,
  },
  {
    title: "Scrreen2",
    inverse: false,
    component: Screen2,
    first: false,
  },
  {
    title: "Scrreen3",
    inverse: false,
    component: Screen3,
    first: false,
  },
  {
    title: "Scrreen4",
    inverse: false,
    component: Screen4,
    first: false,
  },
];
