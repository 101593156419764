import { useIntl } from "gatsby-plugin-intl";
import * as React from "react";
import Landing from "../components/Landing";
import SEO from "../components/Seo";

interface IProps {}

const IndexPage = (props: IProps) => {
  const { formatMessage: fm } = useIntl();
  return (
    <>
      <SEO title={fm({ id: "home-title" })} />

      {/* <Navigation /> */}

      <Landing />
    </>
  );
};

export default IndexPage;
