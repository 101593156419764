import * as React from "react";
import routers from "./routers";
import "./index.scss";
import { useEffect } from "react";

import Navigation from "../Navigation";
import { useRef } from "react";
import { useState } from "react";
// import Header from "../Header";
import AOS from "aos";
import sal from "sal.js";

interface IProps {}

const Landing = (props: IProps) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const timer: any = useRef(null);
  const containerRef: any = useRef();
  useEffect(() => {
    if (typeof window !== undefined) {
      let tmp = window.scrollY;
      window.addEventListener("scroll", (e: any) => {
        let atSnappingPoint = e.target.scrollLeft % e.target.offsetWidth === 0;
        const timeOut = atSnappingPoint ? 0 : 150;
        const index = Math.floor(window.scrollY / window.innerHeight);
        // clearTimeout(timer.current);
        // timer.current = setTimeout(function () {
        // }, timeOut);
        if (window?.scrollY > tmp) {
          // down
          setCurrentIndex(Math.ceil(window.scrollY / window.innerHeight));
        } else {
          // up
          setCurrentIndex(Math.floor(window.scrollY / window.innerHeight));
        }
        tmp = window.scrollY;

        // console.log("wtf: ", containerRef.current?.childNodes[0]);
      });

      setTimeout(() => {
        setCurrentIndex(0);
      }, 500);

      AOS.init();
      sal();
    }
  }, []);

  useEffect(() => {
    console.log("current index: ", currentIndex);
  }, [currentIndex]);

  return (
    <>
      <Navigation currentIndex={currentIndex} isHome={false} />

      <main
        className="snap-container"
        ref={(ref) => (containerRef.current = ref)}
      >
        {routers.map((route, screenIndex) => {
          const Comp = route.component;
          return (
            <section
              className={`snap-section effects ${
                currentIndex === screenIndex ? "is-active" : ""
              }`}
            >
              <Comp active={currentIndex === screenIndex} />
            </section>
          );
        })}
      </main>
    </>
  );
};

export default Landing;
