import React, { useState, useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import Effect from "./Effect";
import SubHeader from "../Header/SubHeader";
const Screen3 = ({ active }) => {
  const { formatMessage: fm } = useIntl();
  return (
    <>
      <SubHeader
        active={active}
        inverse
        key={`${active ? "sub-3" : "sub-3-disabled"}`}
      />
      <div className="h-full lg:overflow-hidden relative flex w-full">
        <Effect active={active} />
        <div
          className="w-full hidden lg:block absolute -left-1/4 bottom-0 -top-1/3  h-full"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-easing="linear"
          data-aos-delay="1000"
        >
          <StaticImage
            src="../../images/section_3.jpg"
            alt=""
            className="object-cover transform scale-150 w-full h-full"
          />
        </div>
        {/* <div className="w-full hidden lg:block absolute right-0 bottom-0 top-0 bg-black h-full">
          <StaticImage src={bg4} alt="" />
        </div> */}
        <div className="w-full  text-white h-full relative">
          <div className="absolute top-0 left-0 bottom-0 right-0 bg-black bg-opacity-70"></div>
          <div className="container px-16 mx-auto pt-20">
            <div className="flex flex-col lg:flex-row mt-10 lg:mt-32 lg:space-x-16">
              <span
                className="hidden lg:block text-sm font-bold lowercase"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="linear"
                data-aos-delay="300"
              >
                03
              </span>
              <div
                className="w-full lg:w-3/12 flex space-x-10"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="linear"
                data-aos-delay="300"
              >
                <span className="block lg:hidden text-sm font-bold lowercase">
                  03
                </span>
                <span className="text-sm font-bold lowercase block mb-16">
                  {fm({ id: "home-projects" })}
                </span>
              </div>
            </div>
            <div className="w-full md:ml-28 mt-10 lg:w-1/2">
              <h1
                className="font-cool italic text-lg lg:text-3xl tracking-wider mb-10"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="linear"
                data-aos-delay="300"
              >
                {fm({ id: "home-projects-title" })}
              </h1>
              <p
                className="text-main font-cool italic mb-16"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="linear"
                data-aos-delay="300"
              >
                {fm({ id: "home-projects-desc" })}
              </p>
              {/* <div
                className="flex space-x-16"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-easing="linear"
                data-aos-delay="300"
              >
                <div className="w-32 border-t border-white border-opacity-30 flex flex-col items-center justify-center py-5">
                  <strong className="font-cool text-5xl">12</strong>
                  <span>{fm({ id: "projects-branch" })}</span>
                </div>
                <div className="w-32 border-t border-white border-opacity-30 flex flex-col items-center justify-center py-5">
                  <strong className="font-cool text-5xl">18</strong>
                  <span>{fm({ id: "projects-project" })}</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Screen3;
